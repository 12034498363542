@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    text-decoration: none;
  }
}
