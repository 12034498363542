@import '../../../styles/customMediaQueries.css';
@import '../PortfolioListingCard.module.css';

.videoWrapper {
  position: relative;
  cursor: pointer;
  background-color: #000;
  overflow: hidden;
}

.videoContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.videoThumbnail {
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.playButton:hover {
  opacity: 1;
}

.videoModal .ant-modal-content {
  background: black;
}

.modalVideo {
  max-width: 90vw;
  height: auto;
  max-height: 80vh;
  object-fit: contain;
}

.unsupportedLabel {
  text-align: center;
  color: white;
}
