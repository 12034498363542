.root {
  display: flex;
  align-items: center;
  background: var(--boxShadowLight);
  gap: 8px;
}

.scrollButtonLeft,
.scrollButtonRight {
  flex: 0 0 auto;
  z-index: 2;
}

.linksContainer {
  flex: 1 1 auto;
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 8px 0;
  gap: 16px;
}

.linksContainer::-webkit-scrollbar {
  display: none;
}

.truncated {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 0 0 auto;
  display: inline-block;
}

.defaultLink {
  color: var(--colorGrey500);
  text-decoration: none;
  font-size: 14px;
}

.defaultLink:hover {
  color: var(--colorGrey900);
  text-decoration: none;
}

.activeLink {
  color: var(--marketplaceColor);
  font-weight: bold;
}
